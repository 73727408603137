.cert-buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 5px;
}

.error_card {
  text-align: center;
  padding: 15px;
}

.alert-error {
  margin: 5px;
}
